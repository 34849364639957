import { AppBar, Toolbar, Button, Box, Switch, Typography, CircularProgress, SvgIcon, Icon, MenuItem, Avatar, Divider, ListItemIcon, Menu, IconButton } from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import useLocalStorage from 'use-local-storage';

import './HeaderComponent.css';
import { useDispatch, useSelector } from "react-redux";

import AuthTokenModel from '../../model/AuthTokenModel';
import { axiosInstance } from "../../utils/AxiosInterceptor";
import { AppDispatch } from "../../store";
import { clearUser } from "../../slices/UserSlice";
import { clearToken } from "../../slices/AuthTokenSlice";
import { useEffect, useState } from "react";
import { Logout, Login, PersonAdd, AccountCircleOutlined, GraphicEq } from "@mui/icons-material";



export default function HeaderComponent() {
  // use dispatch to connect us to the controller
  const dispatch = useDispatch<AppDispatch>();

  // theme stuff
  const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [theme, setTheme] = useLocalStorage('theme', defaultDark ? 'dark' : 'light');

  const sunIcon = "M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z";
  const moonIcon = "M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"

  const darkMode = theme === 'dark';
  const themeModeIcon = darkMode ? moonIcon : sunIcon;

  const switchTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    
  }

  // account menu stuff
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const showAccountMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  // fixing menu theming bc mui is stupid
  const [bgColor, setBgColor] = useState("");
  const [bgColorHover, setBgColorHover] = useState("");
  const [textColor, setTextColor] = useState("");

  useEffect(() => {
    if(theme === 'dark') {
      setBgColor("#181c23");
      setBgColorHover("#282c34");
      setTextColor("#eeeeee");
    }
    else 
    {
      setBgColor("#ffffff");
      setBgColorHover("#eeeeee");
      setTextColor("#181c23");
    }
  }, [theme]);

  // Auth stuff
  const [authToken, setAuthToken] = useLocalStorage('authToken', null);

  // if if user is logged in to determin whether or not to show the login button
  const user = useSelector((state: any) => state.user.user);

  const isLoggedIn = user !== null;

  const logout = () => {
    closeMenu();
    if(authToken) {
      let token = authToken as AuthTokenModel;
      
      let accessToken = token.accessToken;
      let tokenType = token.tokenType;

      axiosInstance.interceptors.request.use( config => {
        // Add the access token if we have it
        config.headers.Authorization =  tokenType + " " + accessToken;
    
        // return the updated config
        return config;
        
      }, error => {
        return Promise.reject(error)
      });

      // make the request to invalidate the token
      axiosInstance.delete('abreviado/api/auth/logout');

      // delete the token on the client side
      setAuthToken(null);
      dispatch(clearUser());
      dispatch(clearToken());

      // TODO: figure out why it needs hard refresh
      window.location.href = '/';
    }
  }

  const titleBar = () => {
    return(
      <Toolbar className="toolbar" variant="dense">
        <RouterLink to="/" className="header-title">
          <Icon className="header-title-icon">
            <GraphicEq />
          </Icon>
          Abreviado
        </RouterLink>
        <Box className="signin-panel">
          {/* <IconButton className="account-icon-button" onClick={showAccountMenu}>
            <AccountCircleOutlined className="header-icon" />
          </IconButton> */}
          <Button className="signup-button" href="https://www.patreon.com/c/Abreviado">
            Sign Up
          </Button>
          <IconButton className="theme-icon-button" onClick={switchTheme} >
            <SvgIcon className="header-icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="var(--text-primary)">
                  <path d={themeModeIcon} /> :
              </svg>
            </SvgIcon>
          </IconButton>
        </Box>
        <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={closeMenu}
        onMouseLeave={closeMenu}
        sx={{ 
          mt: "1px", 
          "& .MuiMenu-paper": 
          { 
            backgroundColor: `${bgColor} !important`, 
            color: `${textColor} !important`,
          }
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: `${bgColor} !important`,
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem disableRipple className="menu-header">
          <ListItemIcon>
          <AccountCircleOutlined sx={{color: `${textColor}`}}/>
          </ListItemIcon>
          {isLoggedIn? 
            <span> Hello {user.firstName}!</span> :
            <span> My Account </span>
          }
        </MenuItem>
        <Divider />
        {!isLoggedIn?
          <div>
            <MenuItem autoFocus key="login" to="/login" component={RouterLink} onClick={closeMenu} sx={{"&:hover": {bgcolor: `${bgColorHover} !important`}}}>
              <ListItemIcon>
                <Login fontSize="small" sx={{color: `${textColor}`}}/>
              </ListItemIcon>
              Log In
            </MenuItem>
            <MenuItem key="signup" to="/signup" component={RouterLink} onClick={closeMenu} sx={{"&:hover": {bgcolor: `${bgColorHover} !important`}}}>
              <ListItemIcon>
                <PersonAdd fontSize="small" sx={{color: `${textColor}`}}/>
              </ListItemIcon>
              Create Account
            </MenuItem>
          </div> : 
          <div>
            <MenuItem onClick={() => logout()} sx={{"&:hover": {bgcolor: `${bgColorHover} !important`}}}>
              <ListItemIcon>
                <Logout fontSize="small" sx={{color: `${textColor}`}}/>
              </ListItemIcon>
              Logout
            </MenuItem>
          </div>
    }
      </Menu>
      </Toolbar>
    );
  };
  
  return (
    <header>
      <AppBar>{titleBar()}</AppBar>
    </header>
  );
}