import { Box, Typography, CircularProgress, Card, Button, IconButton, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import './AddSubscriptionComponent.css';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useLocalStorage from 'use-local-storage';
import { AppDispatch } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { getMySubscriptions } from '../../slices/MySubscriptionsPageSlice';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { MuiTelInput } from 'mui-tel-input';
import { getAllSubscriptionTiers } from '../../slices/SubscriptionTierSlice';
import { getAllLanguages } from '../../slices/LanguageSlice';

function AddSubscriptionComponent({ isActive, inactiveCallback }: any) {

  // call dispatch to get the survey details
  const dispatch = useDispatch<AppDispatch>();

  const [authToken, setAuthToken] = useLocalStorage('authToken', null);

  // define internal state
  const [whatsappNumber, setWhatsappNumber] = useState('');
  const [subscriptionTier, setSubscriptionTier] = useState('');
  const [nativeLanguage, setNativeLanguage] = useState('');

  let whatsappNumberError = false;
  let subscriptionTierError = false;
  let nativeLanguageError = false;

  // states to draw on form fields
  const [whatsappNumberErrorState, setWhatsappNumberErrorState] = useState(false);
  const [subscriptionTierErrorState, setSubscriptionTierErrorState] = useState(false);
  const [nativeLanguageErrorState, setNativeLanguageErrorState] = useState(false);
  const [oopsErrorState, setOopsErrorState] = useState(false);

  // data to populate drop downs
  const subscriptionTiers = useSelector((state: any) => state.subscriptionTier.subscriptionTiers);
  const subscriptionTiersLoading = useSelector((state: any) => state.subscriptionTier.loading);
  const subscriptionTiersError = useSelector((state: any) => state.subscriptionTier.error);

  const languages = useSelector((state: any) => state.language.languages);
  const languagesLoading = useSelector((state: any) => state.language.loading);
  const languagesError = useSelector((state: any) => state.language.error);

  // error messages for form field helper text
  const [whatsappNumberErrorMessageState, setWhatsappNumberErrorMessageState] = useState("");

  // input styling
  const inputSx = {
    // Set icon color
    "& .MuiSvgIcon-root": {
      color: "var(--accent-1)"
    },
    // Root class for the input field
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor:  "var(--accent-1) !important",
          borderWidth: "2px"
        }
      },
      "&:hover:not(.Mui-focused)": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--accent-1)",
        },
      },
    },
    // Class for the label of the input field
    "& .MuiInputLabel-outlined": {
      "&.Mui-focused": {
        color:  "var(--accent-1)",
      }
    }
  }

  // call dispatch to get the list of subscription tiers
  if (!subscriptionTiers && !subscriptionTiersLoading && !subscriptionTiersError) {
    dispatch(getAllSubscriptionTiers())
  }

  // call dispatch to get the list of languages
  if (!languages && !languagesLoading && !languagesError) {
    dispatch(getAllLanguages())
  }

  // scroll to the top of the page on render
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const buildSubscriptionTierSelector = () => {
    let subscriptionTierMenuItems = subscriptionTiers?.map((subscriptionTierItem: any, index: number) => {
      return (
        <MenuItem key={"subscription-tier-menu-item-" + subscriptionTierItem.id} value={String(subscriptionTierItem.refName)}>{subscriptionTierItem.displayName} - {subscriptionTierItem.description}</MenuItem>
      )
    });

    return subscriptionTierMenuItems;
  }

  const buildLanguageSelector = () => {
    let languageMenuItems = languages?.map((languageItem: any, index: number) => {
      return (
        <MenuItem key={"language-menu-item-" + languageItem.id} value={String(languageItem.refName)}>{languageItem.displayName}</MenuItem>
      )
    });

    return languageMenuItems;
  }

  const handleCancelClick = () => {
    inactiveCallback(false);
  }

  const handleAddClick = () => {
    inactiveCallback(false);
  }

  return (
    <>
      {isActive ?
      <Box className="black-screen">
          <Card className="add-subcsription-card">
            <Box className="add-subscription-title-box">
              <Typography className="add-subscription-title" variant="h5"><b>New Subscription</b></Typography>
              <IconButton className="cancel-add-subscription-icon-button" onClick={handleCancelClick}>
                <HighlightOffIcon />
              </IconButton>
            </Box>
            <Box className="add-subscription-form-box">
              <MuiTelInput 
                className="add-subscription-input"
                label="WhatsApp Number" 
                sx={inputSx}
                error={!!whatsappNumberErrorState}
                helperText={whatsappNumberErrorState? whatsappNumberErrorMessageState : ""}
                variant="outlined"
                onChange={(number) => setWhatsappNumber(number)}
                value={whatsappNumber} 
                required/>
              <FormControl sx={inputSx} className="add-subscription-input" required>
                <InputLabel id="subscription-tier-label">Subscription Tier</InputLabel>
                  <Select
                    className="select-input"
                    labelId="subscription-tier-label"
                    label="Subscription Tier"
                    MenuProps={{ PaperProps: { sx: { maxHeight: 210 } } }}
                    error={!!subscriptionTierErrorState}
                    value={subscriptionTier}
                    onChange={(e) => setSubscriptionTier(e.target.value)}>
                      {buildSubscriptionTierSelector()}
                  </Select>
              </FormControl>
              <FormControl sx={inputSx} className="add-subscription-input" required>
                <InputLabel id="native-language-label">Native Language</InputLabel>
                  <Select
                    className="select-input"
                    labelId="native-language-label"
                    label="Native Language"
                    MenuProps={{ PaperProps: { sx: { maxHeight: 210 } } }}
                    error={!!nativeLanguageErrorState}
                    value={nativeLanguage}
                    onChange={(e) => setNativeLanguage(e.target.value)}>
                      {buildLanguageSelector()}
                  </Select>
              </FormControl>
              <Button className="add-subscription-subscribe-button" onClick={handleAddClick}>
                <AddCircleOutlineIcon /> &nbsp; <Typography variant="button">Subscribe</Typography>
              </Button>
            </Box>
          </Card>
      </Box>:
      null
      }
    </>
  );
}

export default AddSubscriptionComponent;