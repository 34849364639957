import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from "../utils/AxiosInterceptor"

import SubscriptionModel from '../model/SubscriptionModel';

interface SubscriptionPageState {
  subscriptions: SubscriptionModel [] | [];
  createdSubscription: SubscriptionModel | null;
  loading: boolean;
  error: string | null;
}

// Define the initial state
const initialState: SubscriptionPageState = {
  subscriptions: [],
  createdSubscription: null,
  loading: false,
  error: null
};

// TODO: redo this once we have a global axios handler
export const getMySubscriptions = createAsyncThunk('mySubscriptionsPage/retrieveMy', async (parameters:any) => {
  const {authToken} = parameters;

  let accessToken = authToken.accessToken;
  let tokenType = authToken.tokenType;

  axiosInstance.interceptors.request.use( config => {
    // Add the access token if we have it
    config.headers.Authorization =  tokenType + " " + accessToken;

    // return the updated config
    return config;
    
  }, error => {
    return Promise.reject(error)
  });

  const response = await axiosInstance.get('abreviado/api/subscription/my');
  
  //return only the id from the response data
  return response.data;
});

export const createSubscription = createAsyncThunk('mySubscriptionsPage/create', async (newSubscription:SubscriptionModel, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post('abreviado/api/subscription', newSubscription);
    return response.data;
  } catch (error: any) {
    // if we get a response message back with error json (probably a 400 error)
    if (error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    }
    else {
      // if we don't get a response message back with error json (probably a 500 error)
      return rejectWithValue("Oops. Something went wrong...");
    }
  }
});

const mySubscriptionsPageSlice: any = createSlice({
  name: 'mySubscriptionsPage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMySubscriptions.pending, (state) => {
        state.loading = true;
        state.subscriptions = [];
        state.error = null;
      })
      .addCase(getMySubscriptions.fulfilled, (state, action) => {
        state.loading = false;
        state.subscriptions = action.payload;
        state.error = null;
      })
      .addCase(getMySubscriptions.rejected, (state, action) => {
        state.loading = false;
        state.subscriptions = [];
        state.error = action.error.message || 'An error occured while retrieving your subscriptions';
      })
      .addCase(createSubscription.pending, (state) => {
        state.loading = true;
        state.createdSubscription = null;
        state.error = null;
      })
      .addCase(createSubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.createdSubscription = action.payload;
        state.error = null;
      })
      .addCase(createSubscription.rejected, (state, action) => {
        state.loading = false;
        state.createdSubscription = null;
        state.error = action.error.message || 'An error occured while creating subscription';
      })
  }
});

export default mySubscriptionsPageSlice.reducer;